import React, { useEffect } from 'react';

import { useFacebookLandingText } from '@src/hooks/directusHooks/useFacebookLandingText';
import SocialNetworkV2FreePage from '@src/components/social-network-free-page/social-network-v2';
import couponCodeService from '@src/utils/couponCodeService';
import { trackEvent } from '@src/services/tracking/tracking';
import { isSSR } from '@src/services/global';


const lp3_55 = () => {

  useEffect(() => {
    try {
      const targetDate = new Date('2024-09-18');
      const currentDate = new Date();

      if (!isSSR && currentDate < targetDate &&  window.location.hostname !== 'localhost') {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error during date check and redirection:', error);
    }
  }, []); // Only run once

  const { landing, home } = useFacebookLandingText();
  const { setOffer } = couponCodeService();


  const items = [
    {
      title: 'Take your health quiz',
      description: 'Tell us about your diet & lifestyle in just 5 minutes.',
    },
    {
      title: 'Get your personalised pack',
      description: 'We’ll create your pack based on your answers.',
    },
    {
      title: 'Get 55% off your 1st month',
      description: 'Get your personalised vitamins. Change or cancel your plan anytime.',
    },
  ];

  const text = {
    ...landing,
    ...home,
    hero_text: 'Choose vitamins personalised to you and get',
    hero_focus: '55% off your 1st month today ',
    hero_focus_price: null,
    how_it_work_header: 'How it works',
    how_it_works_items_list: items,
    hero_price_element: '<b>1st Pack | <s>£39.95</s> <span>£17.98</span></b>',
    sticky_banner_text: 'Take Quiz & get 55% off',
  };

  useEffect(() => {
    setOffer('4pp', '55off');
    trackEvent('result_55off_view');
  }, []);

  return <SocialNetworkV2FreePage text={text} noindex={true} />;
};

export default lp3_55;
