import React, { FC } from 'react';
import { List, Section } from 'vitl-component-library';
import styles from './benefits-section.module.scss';
import Image from '@src/components/image/image';

const MADE_BY_EXPERTS = [
  'High quality nutrients',
  'Non-GMO',
  'Ultra absorbable ingredients',
  'Vegan friendly',
  'Scientifically-backed formula',
];

const WHAT_YOU_WONT_FIND = [
  'Unnecessary filler ingredients',
  'Poor nutrient forms',
  'Artificial colours or flavourings',
  'Chemicals and preservatives',
  'Sugar',
];

interface IBenefitsSectionProps {
  title: string;
  subtitle: string;
  leftTitle: string;
  leftItems: string[];
  rightTitle: string;
  rightItems: string[];
  image: string;
}

const BenefitsSection: FC<IBenefitsSectionProps> = ({
  title,
  subtitle,
  leftTitle,
  leftItems,
  rightTitle,
  rightItems,
  image,
}) => {
  return (
    <Section className={styles.section} title={title} subTitle={subtitle} backgroundColor='"rgba(255,255,255, 0.80)"'>
      <>
        <div className={styles.benefitsContent}>
          <div className={`${styles.listContainer} ${styles.benefitsList}`}>
            <h5 className={styles.listTitle}>{leftTitle}</h5>
            <List items={leftItems} icon="check" iconColor="#46BC8F" spacing="medium" fontSize="medium" />
          </div>
          <div className={styles.imageContainer}>
            <Image filename={image} alt="pill" width={222} />
          </div>
          <div className={styles.listContainer}>
            <h5 className={styles.listTitle}>{rightTitle}</h5>
            <List items={rightItems} icon="cross" spacing="medium" fontSize="medium" />
          </div>
        </div>
      </>
    </Section>
  );
};

export default BenefitsSection;
