import { graphql, useStaticQuery } from 'gatsby';

export const usePartnersText = () => {
  const { partners } = useStaticQuery(graphql`
    query Partners {
      partners: directusFacebookLanding {
        partners_header
        partner_logos {
          image_hash
        }
      }
    }
  `);
  return partners;
};
