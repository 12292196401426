import React from 'react';

import { useFacebookLandingText } from '@hooks/directusHooks/useFacebookLandingText';
import { JoinTheCommunityUI } from 'vitl-component-library';

const JoinTheCommunity = () => {
  const { landing } = useFacebookLandingText();
  return <JoinTheCommunityUI landing={landing} />;
};

export default JoinTheCommunity;
