import React, { ComponentProps } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { ReviewsUI } from 'vitl-component-library';

export interface IReview {
  reviewerName: string;
  review: string;
}

const Reviews: React.FC<
  ComponentProps<typeof ReviewsUI> & {
    hideImage: boolean;
  }
> = ({
  title,
  items,
  backgroundColor = '',
  showReview = false,
  hideTrustPilot = true,
  variationYellow = false,
  variationLightYellow = false,
  trustpilotIframe = false,
  hideImage = false,
}) => {
  const content = useStaticQuery(graphql`
    query Reviews {
      directusHome {
        reviews_show
        reviews_title
        reviews_items {
          reviewerName
          review
        }
      }
    }
  `);

  const { directusHome } = content;

  const sliderItems = items || directusHome.reviews_items;
  const sectionTitle = title || directusHome.reviews_title;

  const currentImage = () => {
    if (variationYellow) return '/images/gradient-background.png';
    if (variationLightYellow || hideImage) return '';
    return '/images/reviews-bg-tiny.jpeg';
  };

  return (
    <ReviewsUI
      items={sliderItems}
      title={sectionTitle}
      showReview={directusHome.reviews_show || showReview}
      hideTrustPilot={hideTrustPilot}
      variationYellow={variationYellow}
      variationLightYellow={variationLightYellow}
      image={currentImage()}
      backgroundColor={backgroundColor}
      trustpilotIframe={trustpilotIframe}
    />
  );
};

export default Reviews;
