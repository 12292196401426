import React from 'react';
import { Link } from 'gatsby';
import { ExpertApproval, TrustPilot } from 'vitl-component-library';

import Image from '@components/image/image';
import Layout from '@components/layout';
import SEO from '@components/seo';
import PartnersSlider from '@components/partners-slider/partners-slider';
import JoinTheCommunity from '@components/join-the-community/join-the-community';
import BenefitsSection from '@src/features/product-page-components/benefits-section/BenefitsSection';
import useVisibility from '@src/hooks/useVisibility';

import styles from './social-network-free-page.module.scss';
import Reviews from '../reviews/reviews';

interface IProps {
  text: any;
  noindex?: boolean;
}

const REVIEWS = [
  {
    review:
      'Within 2 weeks of taking the vitamins, I noticed a big difference in my mental and physical health and wellbeing. Thank you for providing such an efficient product.',
    reviewerName: 'Hana',
  },
  {
    review:
      'Simply, it worked for me. Felt more energized and mentally positive during the whole day. Very happy with the products.',
    reviewerName: 'Marcin',
  },
  {
    review:
      'Love the personalisation, the purity & quality of the products, love the packaging and have had great, easy interactions with your service teams.',
    reviewerName: 'Lyndy',
  },
];

const StickyButton = ({ text, buttonText }: { text: string; buttonText: string }) => {
  return (
    <div className={styles.sticky}>
      <p>{text}</p>
      <Link to="/consultation" className={styles.heroButton}>
        {buttonText}
      </Link>
    </div>
  );
};

const SocialNetworkV2FreePage = ({ text, noindex = true }: IProps) => {
  const [isAddToBasketVisible, addToBasketRef] = useVisibility<HTMLDivElement>();

  const renderPrice = () => {
    if (text.hero_focus_price) return <span className={styles.price}>{text.hero_focus_price}</span>;
    return '';
  };

  const leftBenefits = [
    'High quality nutrients',
    'Non-GMO',
    'Ultra absorbable ingredients',
    'Vegan friendly',
    'Scientifically-backed formula',
  ];
  const rightBenefits = [
    'Unnecessary filler ingredients',
    'Poor nutrient forms',
    'Artificial colours and flavourings',
    'Chemicals and preservatives',
    'Sugar',
  ];

  return (
    <Layout>
      <SEO title={text.page_title} description={text.page_description} noindex={noindex} />
      <section className={styles.heroSection}>
        <div className={styles.heroImage}>
          <Image hideOnMobile filename={text.hero_background_image.filename_disk} />
          <Image hideBiggerThenMobile filename={text.hero_mobile_background_image.filename_disk} maxWidth={1500} />
        </div>
        <div className={styles.heroContainer}>
          <div className={styles.heroRow}>
            <div className={styles.heroColContent}>
              <h1 className={styles.heroTitle}>{text.hero_title}</h1>
              <p className={styles.subHeader}>{text.hero_text}</p>
              <p className={styles.heroFocus}>
                <span className={styles.border}>
                  {text.hero_focus} {text.hero_focus_price ? '|' : ''} {renderPrice()}
                </span>
                {text.hero_price_element ? (
                  <div
                    className={styles.priceOffer}
                    dangerouslySetInnerHTML={{ __html: text.hero_price_element }}
                  ></div>
                ) : null}
              </p>
              <div ref={addToBasketRef}>
                <Link to="/consultation" className={styles.heroButton}>
                  {text.hero_button_label}
                </Link>
              </div>
              <div className={styles.trustPilotHeroWrapper}>
                <TrustPilot type={'MicroStar'} />
              </div>
            </div>
          </div>
        </div>
      </section>

      {!isAddToBasketVisible && addToBasketRef.current ? (
        <StickyButton text={text.sticky_banner_text} buttonText={text.hero_button_label} />
      ) : null}

      <section className={styles.whiteSection}>
        <h2>{text.how_it_work_header}</h2>
        <hr />
        <div className={styles.howItWorkItems}>
          {text?.how_it_works_items_list.map((item: any, index: number) => (
            <div key={index} className={styles.item}>
              <p className={styles.indexNumber}>{index + 1}</p>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.description}>{item.description}</p>
            </div>
          ))}
        </div>
      </section>

      <PartnersSlider backgroundColor="#EAEAEA" />

      <section className={styles.orangeSection}>
        <div className={styles.containerFlex}>
          <div className={`${styles.side} ${styles.textSide}`}>
            <h2>{text.personalized_header}</h2>
            <hr />
            {text.personalized_list.map((item: any) => (
              <p key={item.text}>{item.text}</p>
            ))}
            <Link to="/consultation" className={styles.personalizedButton}>
              {text.hero_button_label}
            </Link>
          </div>
          <div className={styles.side}>
            <div className={styles.imageNew}>
              <Image width={800} filename="b8bb1d2a-f45c-4c5c-b5c7-1ec93f2082c5.jpg" />
            </div>
          </div>
        </div>
      </section>

      <BenefitsSection
        title="Nothing but goodness"
        subtitle="If your body doesn’t need it, we don’t add it."
        leftTitle="Vitl"
        leftItems={leftBenefits}
        rightTitle="Leading competitor"
        rightItems={rightBenefits}
        image="9a23c3ca-66b8-4f15-9b3e-a899653c095d.png"
      />

      <Reviews
        title="What our customers are saying"
        items={REVIEWS}
        hideTrustPilot={false}
        hideImage={true}
        showReview
        trustpilotIframe={<TrustPilot type={'MicroCombo'} />}
      />

      <ExpertApproval
        title="Expert approved"
        description="We combine the latest in nutritional science alongside your actual dietary and lifestyle data to create the perfect combination of nutrients for your body."
        experts={text.experts}
        backgroundColor="PrimaryLighter"
      />

      <JoinTheCommunity />
    </Layout>
  );
};

export default SocialNetworkV2FreePage;
