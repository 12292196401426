import { graphql, useStaticQuery } from 'gatsby';

export const useFacebookLandingText = () => {
  const { landing, home } = useStaticQuery(graphql`
    query FacebookLanding {
      landing: directusFacebookLanding {
        page_title
        page_description
        hero_title
        hero_description
        hero_focus_text
        hero_focus_price
        hero_background_image {
          filename_disk
        }
        hero_mobile_background_image {
          filename_disk
        }
        hero_button_label
        how_it_work_title
        how_it_works_items {
          title
          description
        }
        personalized_header
        personalized_list {
          text
        }
        personalized_button_label
        personalized_image {
          filename_disk
        }
        without_bs_header
        without_bs_text
        without_bs_items {
          filename_disk
          text
        }
        range_header
        shop_the_range_items {
          range_title
          filename_disk
          path
        }
        review_title
        reviews_items {
          review
          rating
        }
        join_us_header
        join_us_text
        join_us_image_1 {
          filename_disk
        }
        join_us_image_2 {
          filename_disk
        }
        join_us_image_3 {
          filename_disk
        }
        join_us_image_4 {
          filename_disk
        }
        join_us_text_1
        join_us_text_2
        join_us_text_3
        join_us_text_4
        join_us_link_1
        join_us_link_2
        join_us_link_3
        join_us_link_4
      }
      home: directusHome {
        experts {
          name
          qualification
          role
          image {
            title
            filename_disk
          }
        }
      }
    }
  `);
  return {
    landing,
    home,
  };
};
