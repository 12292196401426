import React from 'react';
import { PartnersSliderUI } from 'vitl-component-library';

import { usePartnersText } from '@hooks/directusHooks/usePartnersText';

interface IProps {
  isWhite?: boolean;
  backgroundColor?: string;
}

const PartnersSlider = ({ isWhite, backgroundColor = 'rgba(255,255,255, 0.75)' }: IProps) => {
  const partners = usePartnersText();

  return <PartnersSliderUI partners={partners} isWhite={isWhite} backgroundColor={backgroundColor} />;
};

export default PartnersSlider;
